<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <div @click.stop="$router.go(-1)" class="card-header-icon">
            <b-icon class="has-text-light" size="is-small" icon="arrow-left" />
          </div>
          <p class="card-header-title has-text-light is-uppercase">{{ jenis }}</p>
        </header>
        <div class="card-content events-card table-card-content">
          <div class="card-content events-card">
            <div class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4">Pasien</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ perawatan.pasien }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4">Tgl Masuk</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ perawatan.tanggal_masuk }}</div>
            </div>
            <div class="columns is-mobile">
              <div class="column detail has-text-weight-semibold is-4 capitalize">{{ jenis }}</div>
              <div class="column detail has-text-weight-semibold is-1">:</div>
              <div class="column detail has-text-left">{{ perawatan.komponen }}</div>
            </div>
          </div>
          <b-table
            striped
            hoverable
            :loading="loading"
            :data="perawatan.komponen_list"
            :mobile-cards="false"
          >
            <b-table-column field="aktivitas" label="Aktivitas" v-slot="props">
              <router-link
                :to="{ name: detailUrl, params: {id: props.row.id} }"
              >{{ props.row.aktivitas.pspd }}</router-link>
            </b-table-column>
            <b-table-column field="lokasi" label="Lokasi" v-slot="props">{{ props.row.lokasi }}</b-table-column>
            <b-table-column
              field="materi"
              label="Materi"
              class="capitalize"
              v-slot="props"
            >{{ props.row.aktivitas.kompetensi }}</b-table-column>

            <template slot="empty">
              <section v-if="!loading" class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>Tidak ada data.</p>
                </div>
              </section>
            </template>
          </b-table>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchCreatedMixin from "@/apps/core/mixins/fetchCreatedMixin";
import Perawatan from "../models/aktKompDetail.js";

const URL_DETAIL_MAP = {
  diagnosis: "aktivitas-diagnosis-detail",
  "pengendalian dan pencegahan": "aktivitas-tatalaksana-detail",
  terapi: "aktivitas-pemeriksaan-detail",
};

export default {
  name: "PerawatanAktKompDetail",
  data() {
    this.perawatanMdl = new Perawatan();
    let obv = this.perawatanMdl.getObservables();
    obv.loading = false;
    obv.jenis = this.$route.params.jenis;
    obv.detailUrl = URL_DETAIL_MAP[obv.jenis];
    return obv;
  },
  methods: {
    fetchData() {
      const id = this.$route.params.id;
      const jenis = this.$route.params.jenis;
      const kompId = this.$route.params.kompId;
      this.perawatanMdl.load(id, null, {
        fields_type: jenis,
        komponen: kompId,
      });
    },
  },
  mixins: [onlineCheckMixin, fetchCreatedMixin],
};
</script>


<style scoped lang="scss">
@import "@/apps/core/assets/scss/detailview.scss";

div.table-card-content {
  overflow-x: auto;
  padding: 0 !important;
}
</style>